<template>
  <form id="frm_action" class="parent_page_detail">
  <div class="p-grid list_group_report_detail">
    <div class="p-col-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <ProgressBar v-if="display_progress_bar == true" :value="value_process_import">
          {{name_process_import}}: {{value_process_import}}%
        </ProgressBar>
        <div class="p-col-12 group-button-list-top group-button-list-top-detail" >
<!--          <Button
              label="Xác nhận soạn hàng"
              class=" style-button-common"
          />
          <Button
              label="Xác nhận soạn hàng"
              class=" style-button-common"
          />-->

          <div class="div_search mb-3">
            <div class="div_date div_search_mobi div_pc">
              <label class="p-col-fixed txt-right text_align label_company">Xem thống kê </label>
              <!-- <Calendar class=" margin input_width border_color Calendar width_mobi" dateFormat="dd/mm/yy" selectionMode="range" placeholder="DD/MM/YYYY - DD/MM/YYYY" id="date" v-model="date" :showIcon="true" /> -->
              <Calendar
                  :key="countCalendar"
                  @clear-click="headerOnFilter"
                  @date-select="headerOnFilter"
                  :manualInput="false" v-model="date" placeholder="DD/MM/YYYY - DD/MM/YYYY" class="w-100" appendTo="body" :showButtonBar="true" selectionMode="range" dateFormat="dd/mm/yy" :showIcon="true"/>
            </div>

            <div class="div_company div_search_mobi div_pc">
              <label class="p-col-fixed txt-right text_align label_company ">Công ty</label>
              <Dropdown
                  :key="countDropdown"
                  id="selectType" @change="process_change_company" class="input_text width_mobi " v-model="SelectedCompany" :options="company" optionLabel="text_lable" optionValue="id" placeholder="Chọn" />
            </div>
            <div class="button_import" style=" flex: 1; display: flex; justify-content: end;" v-if="dislay_button== 1">
              <Button v-if="this.role.export == true"   @click="process_click_status('EXPORT_EXCEL','Xuất excel','Xác nhận')" label="Xuất excel" class="p-button-success button" />
            </div>
            <div class="button_import" style=" flex: 1; display: flex; justify-content: end;" v-if="dislay_button== 0 ">
              <Button v-if="this.role.closeout == true"  @click="process_click_status('LOCK_UP','Khóa số','Khóa số')" label="Khóa số" class="p-button-success button" />
            </div>
          </div>

<!--          <Button
              v-if="dislay_button==1"
              label="Xuất excel"
              class=" style-button-common main-style-button"
              @click="process_click_status('EXPORT_EXCEL','Xuất excel','Xác nhận')"
          />
          <Button
              v-if="dislay_button==0"
              label="Khóa số"
              class=" style-button-common main-style-button"
              @click="process_click_status('LOCK_UP','Khóa số','Khóa số')"
          />-->

        </div>
        <!--{{listData}}-->
        <BaseDatatable
          v-if="dataModel"
          :key="componentKey"
          :is_load_fillter="is_load_fillter"
          :tableData="listData"
          :model="dataModel"
          :options="options"
          :getFilterOptions="getFilterOptions"
          :actions="getRowActions()"
          :whereKeyList="whereKeyList"
          :filtersDefault="filters"
          v-on:action-click="onActionClicked"
          v-on:action-list_select_item="action_list_select_item"
          v-on:emit_filter="emit_filter_func"
        ></BaseDatatable>
        <!-- <Popup
          :key="editDialogKey"
          :show="displayDialog"
          :objKey="editId"
          :objModelName="modelName"
          :mode="dialogMode"
          @onChange="onChange()"
        ></Popup> -->
        <Dialog
            header="Xuất excel"
            :visible.sync="showExportExcelDialog"
            :contentStyle="{overflow: 'visible'}"
            :modal="true"
        >
          <div class="div_date margin_botton">
            <label class="p-col-fixed txt-right text_align label_company">Báo cáo chi phí DVVH tháng</label><br>
            <Calendar view="month" style="width: 100%!important;" :yearNavigator="true" yearRange="2000:2050" class=" margin input_width border_color Calendar width_input_popup" dateFormat="mm/yy" placeholder="mm/yy"  id="date" v-model="import_excel.date" :showIcon="true" />
          </div>
          <div class="p-card-field margin_botton">
            <label class="p-col-fixed txt-right label_company">Kính gửi <span style="color: red">*</span></label>
            <div class="p-col" style="padding: 0px">
              <InputText style="width: 100%!important;" class="p-col-width input_text border_color width_input_popup" maxlength="255" v-model="import_excel.dear"  id="name" type="text"  placeholder="Nhập " />
            </div>
          </div>
          <strong></strong>
          <template
              #footer
          >


            <Button
                label="Hủy"
                @click="showExportExcelDialog = false"
                icon="pi pi-times"
                class="p-button-warning callback-btn"
            />
            <Button label="Xuất" icon="pi pi-check" class="main-style-button" @click="export_excel()" />
          </template>
        </Dialog>
        <Dialog
          header="Xóa"
          :visible.sync="showDeleteDialog"
          :contentStyle="{overflow: 'visible'}"
          :modal="true"
        >
          Bạn có chắc muốn xóa <strong>{{this.deleteName}}</strong>
          <template
            #footer
          >
            <Button label="Xóa" @click="deleteData()" icon="pi pi-check" class="p-button-danger" />
            <Button
              label="Hủy"
              @click="showDeleteDialog = false"
              icon="pi pi-times"
              class="p-button-warning callback-btn"
            />
          </template>
        </Dialog>
        <Dialog
            :modal="true"
            :showHeader="true"
            position="center" class="custom-popup-common style-popup-update-pakage" header="Cập nhật kiện hàng" footer="Footer" :visible.sync="data_popup_update_pakage.display">
          <div class="text_confirm_popup">Bạn xác nhận đơn hàng đã được soạn hàng xong với thông tin số kiện được liệt kê bên dưới và sẵn sàng cho hoạt động điều phối.</div>
          <div class="group-input-popup">
            <label class="p-col-fixed txt-right">Mã đơn hàng</label>
            <div class="p-col">
              <InputText id="code_order_pakage"  v-model="data_popup_update_pakage.obj_data.code"  type="text" class="p-col-width style_inpuit_order" placeholder="Nhập" />
            </div>
          </div>
          <div class="group-input-popup">
            <div class="p-col">
              <Checkbox id="data_popup_update_pakage_combine_packages" v-model="data_popup_update_pakage.obj_data.combine_packages" :binary="true" />
              <label for="data_popup_update_pakage_combine_packages" class="title_check_box">Ghép kiện</label>
            </div>
          </div>

          <div class="group-input-popup" v-if="data_popup_update_pakage.obj_data.combine_packages ==true">
            <label class="p-col-fixed txt-right">Đơn hàng muốn ghép</label>
            <div class="p-col">
              <InputText id="code_combine_packages" v-model="data_popup_update_pakage.obj_data.code_combine_packages"  type="text" class="p-col-width" placeholder="Nhập" />
            </div>
          </div>
          <!--

          <li v-for="({ message }, index) in items">
            {{ message }} {{ index }}
          </li>
          -->

          <div class="group-input-popup" v-for="(item_pakage, index_pa) in data_popup_update_pakage.list_pakage_data" :key="item_pakage.index_pa">
            <div class="item-left-popup" style="">
              <label class="p-col-fixed txt-right" v-if="index_pa==0">Kiện hàng</label>
              <div class="p-col ">
                <Dropdown class="pakage_popup_dropdown" v-model="item_pakage.parcel_id" :options="data_popup_update_pakage.list_parcel" optionLabel="name" optionValue="id" placeholder="Chọn" />
                <!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
              </div>
            </div>
            <div class="item-right-popup" style="">
              <label class="p-col-fixed txt-right" v-if="index_pa==0">Số lượng</label>
              <div class="p-col style_group_pakage">
                <InputText  v-model="item_pakage.parcel_qty"  type="text" class="p-col-width popup_qty_pakage" placeholder="Nhập" />
                <img  @click="delete_pakage_popup(item_pakage.key_data)" class="style_icon_del_pakage" style="" src="/assets/images/icon/ic_delete.svg">
              </div>
            </div>
          </div>

          <div class="group-input-popup">
            <div class="add_item_popup" @click="add_pakage_popup()">Thêm kiện hàng</div>
          </div>

          <template #footer>
            <Button
                icon="pi pi-times" iconPos="left"
                label="Hủy"
                @click="data_popup_update_pakage.display = false"
                class="p-button-success style-button-cancel-popup "
            />
            <Button
                icon="pi pi-check" iconPos="left"
                label="Xác nhận"
                @click="process_submit_pakage()"
                class="p-button-success main-style-button"
            />
          </template>
        </Dialog>

        <Dialog
            :modal="true"
            :showHeader="true"
            position="center" class="custom-popup-common style-popup-update-pakage" header="Import không thành công" footer="Footer" :visible.sync="data_popup_import_faild.display">
          <div class="text_confirm_popup">{{data_popup_import_faild.text_err}}</div>
<!--          <div class="group-input-popup">
            <label class="p-col-fixed txt-right">Mã đơn hàng</label>
            <div class="p-col">
              <InputText id="code_order_pakage"  v-model="data_popup_update_pakage.obj_data.code"  type="text" class="p-col-width style_inpuit_order" placeholder="Nhập" />
            </div>
          </div>
          <div class="group-input-popup">
            <div class="p-col">
              <Checkbox id="data_popup_update_pakage_combine_packages" v-model="data_popup_update_pakage.obj_data.combine_packages" :binary="true" />
              <label for="data_popup_update_pakage_combine_packages" class="title_check_box">Ghép kiện</label>
            </div>
          </div>-->

<!--          <div class="group-input-popup" v-if="data_popup_update_pakage.obj_data.combine_packages ==true">
            <label class="p-col-fixed txt-right">Đơn hàng muốn ghép</label>
            <div class="p-col">
              <InputText id="code_combine_packages" v-model="data_popup_update_pakage.obj_data.code_combine_packages"  type="text" class="p-col-width" placeholder="Nhập" />
            </div>
          </div>-->
          <!--

          <li v-for="({ message }, index) in items">
            {{ message }} {{ index }}
          </li>
          -->

<!--          <div class="group-input-popup" v-for="(item_pakage, index_pa) in data_popup_update_pakage.list_pakage_data" :key="item_pakage.index_pa">
            <div class="item-left-popup" style="">
              <label class="p-col-fixed txt-right" v-if="index_pa==0">Kiện hàng</label>
              <div class="p-col ">
                <Dropdown class="pakage_popup_dropdown" v-model="item_pakage.parcel_id" :options="data_popup_update_pakage.list_parcel" optionLabel="name" optionValue="id" placeholder="Chọn" />
                &lt;!&ndash;                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />&ndash;&gt;
              </div>
            </div>
            <div class="item-right-popup" style="">
              <label class="p-col-fixed txt-right" v-if="index_pa==0">Số lượng</label>
              <div class="p-col style_group_pakage">
                <InputText  v-model="item_pakage.parcel_qty"  type="text" class="p-col-width popup_qty_pakage" placeholder="Nhập" />
                <img  @click="delete_pakage_popup(item_pakage.key_data)" class="style_icon_del_pakage" style="" src="/assets/images/icon/ic_delete.svg">
              </div>
            </div>
          </div>-->

          <div class="group-input-popup">
            <div class="add_item_popup" @click="download_file_err()">Bấm vào đây để tải file lỗi</div>
          </div>

          <template #footer>
            <Button
                icon="pi pi-times" iconPos="left"
                label="Hủy"
                @click="data_popup_import_faild.display = false"
                class="p-button-success style-button-cancel-popup "
            />
            <Button
                icon="pi pi-check" iconPos="left"
                label="Đã hiểu"
                @click="data_popup_import_faild.display = false"
                class="p-button-success main-style-button"
            />
          </template>
        </Dialog>

        <Dialog
            :modal="true"
            :showHeader="true"
            position="center" class="custom-popup-common style-popup-lock-up" header="Xác nhận khoá số" footer="Footer" :visible.sync="display_lock_up">
          <div class="text_confirm_popup">Sau khi khoá sẽ không thể chỉnh sửa số liệu được nữa? Bạn chắc chắn muốn khoá số các dòng đã chọn?</div>
          <template #footer>
            <Button
                icon="pi pi-times" iconPos="left"
                label="Hủy"
                @click="display_lock_up = false"
                class="p-button-success style-button-cancel-popup "
            />
            <Button
                icon="pi pi-check" iconPos="left"
                label="Xác nhận"
                @click="process_lock_up()"
                class="p-button-success main-style-button"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
  </form>
</template>

<script>
import DataServices from '@/core/DataServices'
import BaseDatatable from './list'
import gql from "graphql-tag";
import ApiRepository from "@/core/ApiRepository";
//import configJson from '../../config';
import axios from 'axios'
import moment from "moment-timezone";
import AuthenticationApp from "@/core/AuthenticationApp";
// import Popup from './popup'
export default {
  components: {
    BaseDatatable,
    // Popup
  },
  data() {
    return {
      is_load_fillter: 1,
      countDropdown: 1,
      countCalendar: 1,
      SelectedCompany:null,
      filters: {},
      filtersCurrent: {},
      date: null,
      import_excel:{
        date:null,
        dear:'',
        cost:''
      },
      display:null,
      display_closeout:null,
      SelectCompany:null,
      company:[],
      showExportExcelDialog :false,
      display_lock_up :false,
      list_map_code_order:{},
      name_process_import:'',
      value_process_import:0,
      display_progress_bar :false,
      files:[],
      dislay_button:1,
      data_popup_import_faild:{
        display:false,
        text_err:"",
        link_err:"",
        /*list_pakage_data:[],
        list_parcel:[],
        obj_data:{
          combine_packages:false,
          code_combine_packages:'',
          id_combine_packages:''
        }*/
      },
      data_popup_update_pakage:{
        display:false,
        list_pakage_data:[],
        list_parcel:[],
        obj_data:{
          combine_packages:false,
          code_combine_packages:'',
          id_combine_packages:''
        }
      },
      order_id_pakage:null,
      list_select_item:[],
      filterOptions: {},
      options: [],
      listData: [],
      modelName: '',
      dataModel: null,
      title: '',
      componentKey: 0,
      editId: null,
      displayDialog: false,
      editDialogKey: 0,
      dialogMode: 'edit',
      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,
      whereKeyList:{
        where_key: {
          /*accounts: {
            role: {_eq: "admin"},
            deleted: {_eq: false}
          },*/
          deleted: {_eq: false}
        }
      },
      page_transaction:null,
      role: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        export: false,
        closeout: false,
        edit_delivery_trip: false,
      },
      display_edit_delivery_trip:false,
      baseUrl:'',
      list_status_change:{}
    }

  },
  mounted () {
    this.initParams();
  },
  async created() {
   // console.log("this.$CoreService.baseurl():",this.$CoreService.baseurl())
   // var envConfig = (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'test') ? process.env.NODE_ENV:'development';
    //this.baseUrl = configJson[envConfig].base_url_api;




   // this.modelName = this.$route.meta.model;
    //this.dataModel = DataServices.getModel(this.modelName);
    //console.log('this.dataModel:',this.dataModel);
    //this.title = this.dataModel.label
    this.$store.commit('setLoading', true);
    if(this.$route.params.reload) {
      window.location.reload();
    }
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_REPORT_EXPENSE);
    if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0){
      this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    }
    this.role.export = (arr_role.indexOf(this.$constants.ROLE.EXPORT) < 0) ? false:true;
    console.log("this.role.export",this.role.export)
    this.display = this.role.export

    this.role.edit_delivery_trip = (arr_role.indexOf(this.$constants.ROLE.EDIT_DELIVERY_TRIP) < 0) ? false:true;

    console.log("edit_delivery_trip",this.role.edit_delivery_trip)
    this.role.closeout = (arr_role.indexOf(this.$constants.ROLE.CLOSEOUT) < 0) ? false:true;
    this.display_closeout = this.role.closeout
    console.log("closeout",this.role.closeout,this.dislay_button)
    this.get_list_status_change()

  },
  //watch: {
    //'$route': 'initParams',
    /*"date"() {
        if (this.date) {
          console.log("this.datethis.date:",this.date)
            if (!this.$commonFuction.isEmpty(this.date[1]) && !this.$commonFuction.isEmpty(this.date[0])) {
              this.$set(this.filters, 'date_order', {value: {to: moment(this.date[1]).utc().add(24, 'hours').format(), from: moment(this.date[0]).utc().format()}, type_input: 'DateRange'});
            }
        } else {
            this.$delete(this.filters, 'date_order');
        }
    },
    "SelectedCompany"() {
        if (this.SelectedCompany) {
            this.$set(this.filters, 'company_id', {value: this.SelectedCompany, type_input: 'select'});
        } else {
            this.$delete(this.filters, 'company_id');
        }
    }*/
 // },
  methods: {
    callbackFilter(){
      var list_filter =  this.lazyParams.list_filter
      //var obj_filter = {};
      for (let i = 0; i < list_filter.length; i++) {
        var fil = list_filter[i]
        for (let key in fil) {
          var item_data = fil[key]
          this.filtersCustom[key] = fil[key]
          if (item_data.type_input == 'DateRange'){
            var array_data_tmp = [new Date(item_data?.value?.from),new Date(item_data?.value?.to)];
            this.$set(this.filters, key,array_data_tmp );
          }else {
            this.$set(this.filters, key,fil[key].value );
          }
        }
      }
    },
    headerOnFilter() {
      this.get_params_filter()
      this.is_load_fillter = 0;
      this.componentKey++;
    },
     get_params_filter() {
      if (this.date) {//
        console.log("this.datethis.date:",this.date)
        if (!this.$commonFuction.isEmpty(this.date[1]) && !this.$commonFuction.isEmpty(this.date[0])) {
          this.$set(this.filters, 'date_order1', {value: {to: moment(this.date[1]).utc().add(24, 'hours').format(), from: moment(this.date[0]).utc().format()}, type_input: 'DateRange'});
        }
      } else {
        this.$delete(this.filters, 'date_order1');
      }
      console.log("this.SelectedCompany:",this.SelectedCompany)
       this.$delete(this.filters, 'company_id');
      if (this.SelectedCompany) {
        this.$set(this.filters, 'company_id', {value: this.SelectedCompany, type_input: 'select'});
      } else {
        this.$delete(this.filters, 'company_id');
      }
    },
    async process_change_company() {
      this.get_params_filter()
      this.is_load_fillter = 0;
      this.componentKey++;
    },
    async export_excel(){
      var that = this
      try {
        var dataRequest = {...this.import_excel, ...this.filtersCurrent};
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        this.$commonFuction.is_loading(true);
        var item_res = await ApiRepository.post('_api/order/export-detail-cost-service', dataRequest, {headers});
        var key_file  = (item_res["data"]["data"]["key_file"]) ? item_res["data"]["data"]["key_file"]:null
        console.log("item_checkitem_check:",item_res)
        if (item_res){
          var is_process = 0
          var callData  =  window.setInterval(async () => {
            this.$commonFuction.is_loading(false);
            if (is_process == 1){
              return false
            }
            is_process = 1;
            // var item_import  = (response["data"]["data"]) ? response["data"]["data"]:null
            var dataRequestCheck = {
              key_file:key_file,
            }
            console.log("dataRequestCheckdataRequestCheck:",dataRequestCheck)
            try {
              const headers = {
                "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
              };
              var item_res = await ApiRepository.post('_api/order/check-export-excel-status', dataRequestCheck, {headers});
              var item_check  = (item_res["data"]["data"]) ? item_res["data"]["data"]:null
              console.log("item_checkitem_check:",item_check)
              if (item_check){
                var key_process = item_check.process
                var percent = item_check.total
                if (key_process == 'DONE'){
                  var urL_report = item_check.urL_report
                  //window.open(urL_report, '_blank');

                  var dataResponseDownload = await this.$CoreService.callApiDownloadPost('_api/common/read_file_url',{file_url:urL_report},{})
                  var url = URL.createObjectURL(new Blob([dataResponseDownload.data], {
                    type: 'application/vnd.ms-excel'
                  }))
                  const link = document.createElement('a')
                  link.href = url;
                  var file_excel = 'BCChiTiet_'+moment().tz("Asia/Bangkok").format('DDMMYYYY')+'_'+moment().tz("Asia/Bangkok").format('HHmmss')+'.xlsx';
                  link.setAttribute('download', file_excel)
                  document.body.appendChild(link)
                  link.click()

                  clearInterval(callData);
                  this.update_process_bar('Thành công',percent);
                  this.display_progress_bar = false
                  alert('Xuất dữ liệu thành công')
                  this.showExportExcelDialog = false
                }else if (key_process == 'ERROR'){
                  clearInterval(callData);
                  alert('Vui lòng thử lại')
                  this.display_progress_bar = false
                }
                else if (key_process == 'PENDDING'){
                  //Đang kt dữ liệu
                  console.log("asdasd")
                  this.update_process_bar('Đang kết xuất dữ liệu',percent);
                }
              }
              is_process = 0;
              //
              return true
            } catch (err) {
              that.$toast.add({
                severity: "error",
                summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                life: 3000,
              });
              console.log(err);
              return false;
            }

            /* step++;
             if (step > vm.seconds) {
               step = 0;
               var stop = await vm.checkImportProcessing();
               if (stop) {
                 vm.$store.commit('setLoading', false);
                 clearInterval(callData);
               }
             }*/
          }, 5000);
        }
        is_process = 0;
        //
        return true
      } catch (err) {
        that.$toast.add({
          severity: "error",
          summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
          detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
          life: 3000,
        });
        console.log(err);
        return false;
      }
    },
    async process_lock_up() {
      //console.log("asdasdasdasd:",this.list_map_code_order)
      try {
       /* var list_data = [];
        for (var i=0;this.list_select_item.length;i++){
          var code = this.list_select_item[i]
          list_data.push( this.list_map_code_order[code])
        }*/
        this.$commonFuction.is_loading(true);
        var list_data = [];
        for (var i=0;i<this.list_select_item.length;i++){
          var code = this.list_select_item[i]
          var item_data = this.list_map_code_order[code]
          list_data.push(item_data)
        }
        var dataRequest = {
          list_data: list_data
        };
        const headers = {
           "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        console.log("asdasdasdasd")
         await ApiRepository.post('/_api/order/update-lock-up', dataRequest, {headers});
        window.location.reload()
      } catch (err) {
        this.$commonFuction.is_loading(false);
        console.log("errerrerrerr:",err);
      }
    },
    async get_list_status_change() {
      try {
        var dataRequest = {
          //order_id: this.model.id,
        };
        const headers = {
         // "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        var list_action_detail = await ApiRepository.post('/_api/order/get_list_status_change', dataRequest, {headers});
        var STATUS_NEXT_ORDER = (list_action_detail["data"]["data"]["STATUS_NEXT_ORDER"]) ? list_action_detail["data"]["data"]["STATUS_NEXT_ORDER"]:{}
        this.list_status_change = STATUS_NEXT_ORDER
      } catch (err) {
        console.log(err);
      }
    },
    update_process_bar(name,percent) {
      this.display_progress_bar = true
      this.name_process_import = name
      this.value_process_import = percent
    },
    async download_file_err() {
      var filename = this.$CoreService.getFileName(this.data_popup_import_faild.link_err);
      axios({
        url: this.data_popup_import_faild.link_err,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    async chooseFile($event) {
      this.$commonFuction.is_loading(true);
      var that=this
      console.log("asd",$event)
      //if (this.files.length > 0) {

      /*  this.$store.commit('setDataLoading', {content: 'Đang kiểm tra file import', icon: true});
        this.$store.commit('setLoading', true);
        var vm = this;*/
        var file = this.$refs.files.files[0];
        let formData = new FormData();
        formData.append('file', file)
      var headerUpload = {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        }
      };
        await ApiRepository.post('/_api/order/import-misa-excel', formData,headerUpload)
            .then(async (response) => {
              console.log("responseresponse:",response)
            //check status file
              var is_process = 0
              var callData  =  window.setInterval(async () => {
                this.$commonFuction.is_loading(false);
                if (is_process == 1){
                  return false
                }
                is_process = 1;
                var item_import  = (response["data"]["data"]) ? response["data"]["data"]:null
                var dataRequestCheck = {
                  url_file:item_import.url_file,
                }
                console.log("dataRequestCheckdataRequestCheck:",dataRequestCheck)
                try {
                  const headers = {
                    "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
                  };
                  var item_res = await ApiRepository.post('_api/order/check-import-excel-status', dataRequestCheck, {headers});
                  var item_check  = (item_res["data"]["data"]) ? item_res["data"]["data"]:null
                  console.log("item_checkitem_check:",item_check)
                  if (item_check){


                    var key_process = item_check.process
                    var success = item_check.success
                    var total = item_check.total
                    var round_percent = Math.round(success / total * 100) / 100
                    const percentText = parseInt(round_percent * 100);
                    if (key_process == 'FINISH'){
                      clearInterval(callData);
                      this.update_process_bar('Import thành công',100);
                      setTimeout(function () {
                        alert('Import thành công')
                        location.reload();
                      }, 1000);
                    }else if (key_process == 'ERROR'){
                      var number_error = item_check.error
                      var error_file = item_check.error_file
                      this.data_popup_import_faild.text_err =`Tập dữ liệu chứa ${number_error} dòng không hợp lệ, tải file chứa lỗi và điều chỉnh cho lần nhập dữ liệu tiếp theo.`
                      this.data_popup_import_faild.link_err =this.$CoreService.baseurl()+'/'+error_file
                      clearInterval(callData);
                      this.data_popup_import_faild.display = true
                      this.$refs.files.value = null;
                      this.display_progress_bar = false
                      //alert('Lỗi')
                    }else if (key_process == 'IMPORTING'){
                      //đang import
                      console.log("asdasd")
                      this.update_process_bar('Đang import dữ liệu',percentText);
                    }
                    else if (key_process == 'VERIFYING'){
                        //Đang kt dữ liệu
                      console.log("asdasd")
                      this.update_process_bar('Đang kiểm tra dữ liệu',percentText);
                    }
                    else if (key_process == 'VERIFIED'){
                      //đã kiểm tra
                      this.update_process_bar('Đã kiểm tra dữ liệu',percentText);
                    }
                  }
                  is_process = 0;
                  //
                  return true
                } catch (err) {
                  that.$toast.add({
                    severity: "error",
                    summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                    detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                    life: 3000,
                  });
                  console.log(err);
                  return false;
                }

               /* step++;
                if (step > vm.seconds) {
                  step = 0;
                  var stop = await vm.checkImportProcessing();
                  if (stop) {
                    vm.$store.commit('setLoading', false);
                    clearInterval(callData);
                  }
                }*/
              }, 5000);
            })
            .catch(async (error) => {
              console.log("errorerror:",error)
              /*this.$store.commit('setLoading', false);
              vm.$toast.add({ severity: 'error', summary: 'Thông báo', detail: error, life: 3000 })*/
            });

     // }
    },
    async click_import_order() {
      console.log("change_import_order:")
      const elementToClick = document.getElementById('input-file-order');
      const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      });
      elementToClick.dispatchEvent(clickEvent);
    },
    async list_parcel() {
      var gqLQueryListData = DataServices.getList('parcel');
      var where_data = {
        active: {_eq: true},
        deleted: {_eq: false},
      }
      var resData_data = await this.$apollo.mutate({
        mutation: gqLQueryListData,
        variables: {
          where_key:where_data ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{ordinal: 'desc'}
        }
      });
      var listData = resData_data.data['parcel'];
      return listData;

    },
    async validateDataPopupPakage() {
      //var key_data = (this.data_popup_product.obj_product) ? this.data_popup_product.obj_product.key_data:null;

      var code_order_pakage = document.getElementById("code_order_pakage");
      // var is_err = 0;
      if (this.$commonFuction.isEmpty(this.data_popup_update_pakage.obj_data.code)) {
        //     is_err = 1;
        code_order_pakage.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      } else {
        var where_obj1 = {code: {'_eq': this.data_popup_update_pakage.obj_data.code}};
        var obj_data1 = await this.$CoreService.getObjectData('order',where_obj1);
        if (this.$commonFuction.isEmpty(obj_data1)){

          code_order_pakage.setCustomValidity("Đơn hàng không hợp lệ");
        }else {
          this.order_id_pakage= obj_data1.id
          code_order_pakage.setCustomValidity("");
        }

      }

      if (this.data_popup_update_pakage.obj_data.combine_packages){
        if (!this.$commonFuction.isEmpty(this.order_id_pakage)) {
          var code_combine_packages = document.getElementById("code_combine_packages");
          // var is_err = 0;
          if (this.$commonFuction.isEmpty(this.data_popup_update_pakage.obj_data.code_combine_packages)) {
            //     is_err = 1;
            code_combine_packages.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
          } else {
            var where_obj = {
              code: {'_eq': this.data_popup_update_pakage.obj_data.combine_packages},
              id: {_neq: this.order_id_pakage}
            };
            var obj_data = await this.$CoreService.getObjectData('order', where_obj);
            if (this.$commonFuction.isEmpty(obj_data)) {
              code_combine_packages.setCustomValidity("Đơn hàng ghép kiện không hợp lệ");
            } else {
              this.data_popup_update_pakage.obj_data.id_combine_packages = obj_data.id
              code_combine_packages.setCustomValidity("");
            }

          }
        }
      }


      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }

    },
    async process_submit_pakage() {
      var that = this
      if (await this.validateDataPopupPakage()) {
        // this.saving = false;
        // this.$commonFuction.is_loading(false);
        return
      }
      if (this.data_popup_update_pakage.list_pakage_data.length <1){
        alert("Vui lòng thêm kiện hàng");
        return;
      }
      //data_popup_update_pakage.obj_data.code
      var dataRequest = {
        id:this.order_id_pakage,
        combine_packages:this.data_popup_update_pakage.obj_data.combine_packages,
        list_pakage_data:this.data_popup_update_pakage.list_pakage_data,
        id_combine_order:this.data_popup_update_pakage.obj_data.id_combine_packages,
      }

      try {
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        await ApiRepository.post('/_api/order/process-update-pakage', dataRequest, {headers});
        that.$toast.add({
          severity: "success",
          summary: "Thông báo",
          detail: "Cập nhật thành công",
          life: 3000,
        });
        location.reload()
        return true
      } catch (err) {
        that.$toast.add({
          severity: "error",
          summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
          detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
          life: 3000,
        });
        console.log(err);
        return false;
      }
    },
    async add_pakage_popup() {
      var item_pakage = {
        parcel_id:null,
        parcel_qty:0,
        key_data:(new Date()).getTime()+this.$commonFuction.makeId(8)
      }
      this.data_popup_update_pakage.list_pakage_data.push(item_pakage)
    },
    async delete_pakage_popup(key_data) {
      var list_pakage_data = [];
      var list_pakage_data_convert = this.data_popup_update_pakage.list_pakage_data;
      for (var i=0;i<list_pakage_data_convert.length;i++){
        var item_data = list_pakage_data_convert[i];
        var key_data_check = item_data.key_data;
        if (key_data!==key_data_check){
          list_pakage_data.push(item_data)
        }
      }
      this.data_popup_update_pakage.list_pakage_data = list_pakage_data
    },
    emit_filter_func(data) {
      //Lấy danh sách đơn hàng
      this.filtersCurrent = data
    },
    action_list_select_item(data) {
      //Lấy danh sách đơn hàng
      console.log("data:",data)
      this.list_select_item = data["list_select_item"]
      this.list_map_code_order = data["list_map_code_order"]
      if (this.list_select_item.length > 0){
        this.dislay_button=0
      }else {
        this.dislay_button=1
      }
    },
    async process_export_excel() {
      var that = this
      var dataRequest = {
       /* id:this.order_id_pakage,
        combine_packages:this.data_popup_update_pakage.obj_data.combine_packages,
        list_pakage_data:this.data_popup_update_pakage.list_pakage_data,
        id_combine_order:this.data_popup_update_pakage.obj_data.id_combine_packages,*/
      }

      try {
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        await ApiRepository.post('/_api/order/export-detail-cost-service', dataRequest, {headers});



        /*that.$toast.add({
          severity: "success",
          summary: "Thông báo",
          detail: "Cập nhật thành công",
          life: 3000,
        });*/
       // location.reload()
        return true
      } catch (err) {
        that.$toast.add({
          severity: "error",
          summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
          detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
          life: 3000,
        });
        console.log(err);
        return false;
      }
    },
    async process_click_status(type_status,title_page='',button_name='') {
      console.log("type_status:",title_page)
      console.log("type_status:",button_name)
      if (type_status == 'LOCK_UP'){
        this.display_lock_up = true
      }else if (type_status == "EXPORT_EXCEL"){
        console.log("type_status:",type_status)
        this.showExportExcelDialog= true;
        //this.process_export_excel();
      }
    },
    async initParams() {
      var whereAttach ={}
      var listDisplayCompany = AuthenticationApp.getListCompany();
      if (listDisplayCompany != null) {
        //order_shipping(where: {order_shipping_items: {order: {company_id: {_in: ""}}}})
        whereAttach.id =  {_in: listDisplayCompany};
      }
      var listData =await this.$CoreService.getCompanyFilter(whereAttach);
      this.company = listData;
      if (this.company.length > 0){
        this.SelectedCompany = this.company[0]["id"]
      }
      var firstDayOfMonth = moment().startOf('month').format("YYYY-MM-DD");
      var currentDate = moment().format("YYYY-MM-DD");
      if (!this.$commonFuction.isEmpty(this.$route.query)){
        if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
          this.page_transaction = this.$route.query.page_transaction;
          var lazyParamsData = (localStorage.getItem(this.page_transaction)) ? JSON.parse(localStorage.getItem(this.page_transaction)) : {};
          var list_filter = lazyParamsData.list_filter
          for (let i = 0; i < list_filter.length; i++) {
            var fil = list_filter[i]
            for (let key in fil) {
              var item_data = fil[key]
              if (key == 'date_order1'){
                firstDayOfMonth = item_data?.value?.from
                currentDate = item_data?.value?.to
              }else if ((key == 'company_id')){
                this.SelectedCompany = fil[key].value
              }
            }
          }

        }
      }


      this.$set(this, 'date', [new Date(firstDayOfMonth), new Date(currentDate)]);
      this.get_params_filter()
      this.$store.commit('setLoading', true);
      this.modelName = this.$route.meta.model;
      this.dataModel = DataServices.getModel(this.modelName);
      this.title = this.dataModel.label;

      this.callbackOptions();


      this.countDropdown++;
      this.countCalendar++;

      console.log("this.countDropdown:",this.countDropdown)
      console.log("this.countCalendar:",this.countCalendar)
    },
    onChange() {
      this.$store.commit('setLoading', true);
      this.$apollo.queries.listData.refresh()
    },
    getFilterOptions(name) {
      var field = this.dataModel.getField(name)
      var result = []
      if (field.type == 'Selection') {
        //result = field.options;
        if (field.options ) result = field.options;
        else if(field.models && this.options[name]) result = this.options[name];
      } else {
        result = this.filterOptions[name] != null ? [...this.filterOptions[name]] : []
      }
      console.log("field.type:",field.type);
      console.log("resultresultresultresult11:",result);
      return result;
    },
    getRowActions() {
      var actions = {}
      var that = this;
      /*this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD).then( (res)  => {
       if (res){
         actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
       }
      })*/
      /*if (this.role.add) {
        actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
      }*/
      //if ((await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD))){

      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        var buttons = {}
        // buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
       /* if (that.role.edit) {
          buttons.edit = {name: 'edit', icon: 'pencil', key: 'id', path: `list-${that.modelName}/edit/`};
        }*/
        if (that.role.edit_delivery_trip == true){
          buttons.edit_delivery_trip = { name: 'edit', icon: 'pencil', key: 'id', path: `${that.modelName}/edit/`}
        }

       /* if (that.role.delete) {
          buttons.delete = {name: 'delete', icon: 'trash', key: 'id'}
        }*/

        if (that.dataModel.actions) {
          var modelaction = that.dataModel.actions(rowData)
          Object.assign(buttons, modelaction);
        }
        return buttons
      }
      return actions
    },
    async callbackOptions() {
      console.log("tettststststst:ssssss")
      var fields = this.dataModel.getDisplayFields();
      console.log("tettststststst:",fields)
      var attributes = [];
      for (let index = 0; index < fields.length; index++) {
        const element = fields[index];
        if (element.models !== undefined && element.models !== null) {
          var optionsData = [];
          attributes =[]
          attributes.push(element.models.key.key);
          attributes.push(element.models.display_key);
          //console.log("attributesattributesattributes:",attributes)
          //var where_status = {}
          /*if( element['name']== "rel_status"){
            where_status =  {
              type: {'_eq': 'ORDER'}
            }
          }*/
          var where_ = {}
          if( element['name']== "name_group_filter"){
            where_ =  {
              active: {_eq: true},
              deleted: {_eq: false},
            }
            element.models.tableName = 'group'
          }
          console.log("element.models:",element.models);
          var {data} = await this.$apollo.query({
            query: DataServices.getList(element.models.tableName, {"fields" : attributes.join(',')}),
            variables: {where_key : where_}
          })
          if (data) {
            var result = data[Object.keys(data)];
            if (result) {
              for (let index = 0; index < result.length; index++) {
                const ele = result[index];
                optionsData.push({value: ele[attributes[0]], label: ele[attributes[1]]})
              }
            }
          }
          console.log("optionsDataoptionsData:",optionsData)
          this.options[element.key] = optionsData;
        }

      }
      this.componentKey++;
    },
    async onActionClicked(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      var filter = payload?.lazyParams?.filters ?? []
      var list_filter = []
      for (let key in filter) {
        //if (filter.hasOwnProperty(key)) { // Ensure the property belongs to the object itself, not inherited
        console.log(`wwwwwww11 ${key}: ${filter[key]}`);
        //}
        var itemdata={};
        itemdata[key] = filter[key];
        list_filter.push(itemdata)
      }
      payload.lazyParams.list_filter = list_filter;
      var pageTransaction = (new Date()).getTime();
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            /*console.log("payload.lazyParams:111"+pageTransaction,payload.lazyParams);
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })*/

            //this.$router.push({ path: '/admin/list-report_expense/edit/'+data.data[action.key] ,query: { key_tmp: (new Date()).getTime() } })

            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: '/admin/list-report_expense/edit/' + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })

          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          this.deleteId = data.data[action.key]
          this.deleteName = data.data.name
          this.showDeleteDialog = true
          break
        case 'add':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else  {
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'custom':
          await action.handler(data.data, this)
          this.componentKey++
          break
      }
    },
    async deleteData() {
      this.showDeleteDialog = false;
      console.log("testttttttttt");
      this.$commonFuction.is_loading(true);
      var resDataAccount = await this.$CoreService.getAccountByUid(this.deleteId);
      if (resDataAccount && resDataAccount.length > 0){
        //res[0].id;
        // var account_id = resDataAccount[0].id;
        // var phone = this.$commonFuction.convertPhone(resDataAccount[0].account);
        var uid = this.deleteId;
        var that = this;
        //Xóa account cũ
        
        // id mới dc thêm vào
        var userUpdate = {
          'deleted': true,
        };
        var accountUpdate = {
          'deleted': true,
        }
        var queryUpdate = `
            mutation update_users_multil($users_object: users_set_input,$accounts_object: accounts_set_input,$group_user_object: group_user_set_input) {
              update_users(where: { id: {_eq: "${uid}"}}, _set: $users_object) {
                affected_rows
                returning {
                  id
                }
              }
              update_accounts(where: { uid: {_eq: "${uid}"}}, _set: $accounts_object) {
                affected_rows
                returning {
                  id
                }
              }
            }
          `;

        let variables = {
          users_object: userUpdate,
          accounts_object: accountUpdate,
        };
        that.$apollo.mutate({
          mutation: gql(queryUpdate),
          variables: variables
        }).then(async (res) => {
          console.log(res);
        }).then(() => {
          var router_back = {path: '/admin/list-users'};
          if (!that.$commonFuction.isEmpty(router_back)){
            console.log("that.page_transaction:",that.page_transaction);
            router_back.query = { page_transaction: that.page_transaction.toString() };
          }
          that.$router.push(router_back);
          that.$commonFuction.is_loading(false);
          that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Xóa dữ liệu thành công', life: 3000 })
          //that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 })
          window.location.reload()
        });
      }
    }
  },
  /*apollo: {
    listData: {
      query() {
        this.modelName = this.$route.meta.model;
        console.log('8*************************',this.modelName);
        return DataServices.getList(this.modelName);
      },
      variables () {
        // Use vue reactive properties here
        return this.whereKeyList;
      },
      update(data) {
        console.log('this.dataModel.tableName',data);
        var listData = data[this.dataModel.tableName]
        this.title = this.dataModel.label + ' '
        this.dataModel.processData(listData)
        this.$store.commit('setLoading', false);
        this.componentKey += 1;
        console.log('data[this.dataModel.tableName]',data[this.dataModel.tableName]);
        return data[this.dataModel.tableName]
      },
      fetchPolicy: 'network-only',
      error() {
        this.$store.commit('setLoading', false);
      },
      skip() {
        return !this.modelName
      }
    }
  }*/

}
</script>
<style lang="css">
.p-calendar .p-datepicker { width: 280px; padding: 0px; padding-top: 5px;}
.p-calendar .p-datepicker td, .p-calendar .p-datepicker td a {padding: 0px !important;}
.p-calendar .p-datepicker td span {padding: 0px !important; width: 30px !important; height: 30px !important;}
.p-datepicker .p-datepicker-header{padding: 5px !important;}
/*.style-button-common{
  background: #C40380 0% 0% no-repeat padding-box;
  border-radius: 3px;
  color: #FFFFFF;
}*/
</style>
<style lang="scss">
.list_group_report_detail {
  .txt-width_common-comlumn {
    div {
      width: 130px !important;
    }
  }
  .txt-width_common-large {
    div{
      width: 280px !important;
    }
  }
  .group-button-list-top {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 16px;
    padding-right: 0px;

    .style-button-common {
      margin-left: 8px;
     /* margin-right: 8px;*/
      margin-top: 8px;
    }

    .style-button-common:last-of-type {
      margin-right: 0px;
    }
  }
.group-button-list-top-detail{
  display: inline;
  /*text-align: right;*/
}
  .style-popup-update-pakage,.style-popup-lock-up
  {
    width: 600px;
  }
  .style-popup-update-pakage{
    .style_inpuit_order{
      width: 100%;
    }
    .pakage_popup_dropdown{
      width: 100%;
    }
    .item-right-popup{
      width: 212px !important;
    }
    .item-left-popup{
      width:calc(100% - 212px) !important;
    }
    .style_group_pakage{
      position: relative;
      .popup_qty_pakage{
        width: 166px;
      }
      .style_icon_del_pakage{
        cursor: pointer;
        position: absolute;
        width: 38px;
        height: 32px;
        right: 0px;
        margin-top: auto;
        margin-bottom: auto;
        top: 0px;
        bottom: 0px;
      }
    }
  }
  .import-order-btn{
    position: relative;
    display: inline;
    .input-file-order{
      position: absolute;
    }
  }
  .p-col-12{
    padding: 0px;
  }
}
</style>
